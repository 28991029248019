import { createSlice } from '@reduxjs/toolkit'
import { GLOBAL_CONFIG } from 'configs/AppConfig'

export const initialState = GLOBAL_CONFIG

export const globalSlice = createSlice({
	name: 'Global',
	initialState,
	reducers: {
        setTabsIndex: (state, action) => {
			state.tabsIndex = action.payload
		},
		setOpenModal:(state,action)=>{
			state.isModalOpen=action.payload
		},
		setError:(state,action)=>{
			state.error=action.payload
		}
        
	},
})

export const { 
    setTabsIndex,
	setOpenModal,
	setError
} = globalSlice.actions

export default globalSlice.reducer