import React, { lazy, Suspense, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { useLocation } from "react-router-dom";
import { B2bRefrenceCode } from "store/slices/authSlice";

// Lazy loading for AppLayout and AuthLayout to optimize performance.
const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

/**
 * The Layouts component is responsible for rendering the appropriate layout
 * based on the application's state.
 */
const Layouts = () => {
  // Using Redux useSelector hook to access the global state.
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search);
  const campaignID = queryParams.get('campaignID');

  useEffect(() => {
    if (campaignID) {
      const payload = {
        "campaignId": campaignID
      }

      dispatch(B2bRefrenceCode(payload));
    }
  }, [])


  // Deciding between AppLayout and AuthLayout based on the presence of a token and layout preference.
  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  // Accessing locale, direction, and theme from the global state.
  //   const locale = useSelector(state => state.theme.locale);
  const locale = localStorage?.getItem("lang");
  const direction = useSelector((state) => state.theme.direction);
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  // Obtaining the locale configurations for internationalization.
  const currentAppLocale = resources[locale];

  // Dynamically adding a class to the body tag based on the direction.
  useBodyClass(`dir-${direction}`);

  // Setting the theme configuration based on the current theme.
  const themeConfig = currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };


  // Wrapping the layout in ConfigProvider to pass down Ant Design configurations.
  // Suspense is used for lazy-loaded components with a fallback loading component.
  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale?.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

// Memoizing the component to prevent unnecessary re-renders.
export default memo(Layouts);
