import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API, { BASE_URL } from "utils/service";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  allStores: [],
  isStroreCreated: false,
  isStoreTypeCreated: false,
  allStoreType: [],
  allStoreDropDownList: [],
  allStoreInventoryList: [],
  getStoreById: [],
  singleStore: {}
};

export const getAllStoresList = createAsyncThunk(
  "product-management/stores/list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/store/list?page=${params.page}${updatedByParam}${query}${startDateParam}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : ""}`
      );

      return response?.data?.payload?.content;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllStoresListDropDown = createAsyncThunk(
  "product-management/stores/list-DropDown",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/store/list`
      );
      let value = response?.data?.payload?.content.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateStore = createAsyncThunk(
  "product-management/create-stores",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/store`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleStore = createAsyncThunk(
  "product-management/single-store-inventory",
  async (param, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/store-inventory/${param}`,

      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const CreateStoreInventory = createAsyncThunk(
  "product-management/create-store-inventory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/store-inventory`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateBulkStoreInventory = createAsyncThunk(
  "product-management/create-store-inventory/save/bulk-customer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/store-inventory/save/bulk-customer`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllStoreInventory = createAsyncThunk(
  "product-management/all-store-inventory",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/store-inventory/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : "&sort=si.id,desc"}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllFinancingApplicationChannels = createAsyncThunk(
  "finance/financing-application-channels",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/financing-application-channels/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : "&sort=id,desc"}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllFinancingApplicationStatus = createAsyncThunk(
  "finance/financing-application-statuses",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/financing-application-statuses/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : "&sort=id,desc"}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllInstallmentPeriod = createAsyncThunk(
  "finance/installment-period-types-list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/finance/v1/installment-period-types/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : "&sort=id,desc"}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteStoreInventory = createAsyncThunk(
  "product-management/delete-store-inventory",
  async (params, { rejectWithValue }) => {
    try {

      const response = await API.delete(
        `/product-management/v1/store-inventory/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteStore = createAsyncThunk(
  "product-management/delete-store",
  async (params, { rejectWithValue }) => {
    try {

      const response = await API.delete(
        `/product-management/v1/store/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateStoreType = createAsyncThunk(
  "product-management/create-store-type",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/store-type`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllStoreType = createAsyncThunk(
  "product-management/get-store-type",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/store-type/list`,
        data
      );
      let value = response?.data?.payload?.content.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getStoreById = createAsyncThunk(
  "product-management/stores/get-store-by-id",
  async (storeId, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/store/${storeId}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);



export const updateStoreById = createAsyncThunk(
  "product-management/update-store",
  async ({ data, pathID }, { rejectWithValue }) => {
    try {
      const response = await API.patch(`${BASE_URL}/product-management/v1/store/${pathID}`, data);

      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const storeSlice = createSlice({
  name: "storeManagement",
  initialState,
  reducers: {
    setStoreCreated: (state, acion) => {
      state.isStroreCreated = acion.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoresList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoresList.fulfilled, (state, action) => {
        state.loading = false;
        state.allStores = action.payload;
      })
      .addCase(getAllStoresList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(CreateStore.pending, (state) => {
        state.loading = true;
        state.isStroreCreated = false;
      })
      .addCase(CreateStore.fulfilled, (state, action) => {
        state.loading = false;
        state.isStroreCreated = true;
      })
      .addCase(CreateStore.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isStroreCreated = false;
      })
      .addCase(CreateStoreType.pending, (state) => {
        state.loading = true;
        state.isStoreTypeCreated = false;
      })
      .addCase(CreateStoreType.fulfilled, (state, action) => {
        state.loading = false;
        state.isStoreTypeCreated = true;
      })
      .addCase(CreateStoreType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isStoreTypeCreated = false;
      })
      .addCase(getAllStoreType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoreType.fulfilled, (state, action) => {
        state.loading = false;
        state.allStoreType = action.payload;
      })
      .addCase(getAllStoreType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllStoresListDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStoresListDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allStoreDropDownList = action.payload;
      })
      .addCase(getAllStoresListDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllStoreInventory.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllStoreInventory.fulfilled, (state, action) => {
        state.loading = false;
        state.allStoreInventoryList = action.payload;
      })
      .addCase(GetAllStoreInventory.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getStoreById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStoreById.fulfilled, (state, action) => {
        state.loading = false;
        state.storeData = action.payload; // Assuming payload contains store data
        state.error = null;
      })
      .addCase(getStoreById.rejected, (state, action) => {
        state.loading = false;
        state.storeData = null;
        state.error = action.payload; // Assuming payload contains error message
      })
      .addCase(updateStoreById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStoreById.fulfilled, (state, action) => {
        state.loading = false;
        state.storeData = action.payload; // Assuming payload contains store data
        state.error = null;
      })
      .addCase(updateStoreById.rejected, (state, action) => {
        state.loading = false;
        state.storeData = null;
        state.error = action.payload; // Assuming payload contains error message
      })
      .addCase(getSingleStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleStore.fulfilled, (state, action) => {
        state.loading = false;
        state.singleStore = action.payload; // Assuming payload contains store data
        state.error = null;
      })
      .addCase(getSingleStore.rejected, (state, action) => {
        state.loading = false;
        state.storeData = null;
        state.error = action.payload; // Assuming payload contains error message
      });
  },
});

export const { setStoreCreated } = storeSlice.actions;

export default storeSlice.reducer;
