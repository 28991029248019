import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { APP_PREFIX_PATH, AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import { GetUserOrganization } from 'utils/DefaultFunctions';

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth)
	const organization = GetUserOrganization() === "Outback Supply International";
	let replaceto=organization ? `${APP_PREFIX_PATH}/allprofiles` : AUTHENTICATED_ENTRY

	return token ? <Navigate to={replaceto} /> : <Outlet/>
}

export default PublicRoute