import React from "react"; // Import React library for building UI components
import { Select } from "antd"; // Import Select component from Ant Design library

/**
 * CustomSelect component
 * @param {Object} props - Props for the CustomSelect component
 * @param {Object} props.style - Style object to customize the appearance of the Select component
 * @param {string} props.placeholder - Placeholder text for the Select component
 * @param {Array} props.options - Array of options for the Select component
 * @param {string} props.value - Currently selected value for the Select component
 * @param {Function} props.onChange - Function to handle change events on the Select component
 * @param {boolean} props.disabled - Boolean value to disable the Select component
 * @param {string} props.defaultValue - Default value for the Select component
 * @returns {JSX.Element} - JSX component for the custom select input
 */

const CustomSelect = ({ style, placeholder, options, value, onChange, disabled, defaultValue, className }) => {
  return (
    <Select
      className={className}
      showSearch // Enable search functionality in the Select component
      allowClear={true} // Allow clearing the selected value
      style={style} // Apply custom styles to the Select component
      placeholder={placeholder} // Set the placeholder text for the Select component
      value={value} // Ensure the selected value is passed to the Select component
      onChange={onChange} // Ensure the onChange prop is correctly used
      optionFilterProp="children" // Set the property used for filtering options
      disabled={disabled}  // Set the disabled state of the Select component
      filterOption={(input, option) =>
        option?.children ? option?.children?.toLowerCase()?.includes(input?.toLowerCase()) : false
      }
      filterSort={(optionA, optionB) =>
        (optionA?.children || "")?.toLowerCase()?.localeCompare((optionB?.children || "")?.toLowerCase())
      }
      defaultValue={defaultValue} // Set the default value for the Select component

    >
      {options?.map(
        (
          option // Render the options
        ) => (
          <Select.Option key={option?.value} value={option?.value}>
            {option?.label}
          </Select.Option>  // Render the options for the Select component
        )
      )}
    </Select>
  );
};

export default CustomSelect;
