import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetToken } from 'store/slices/FinanceManagement/financSlice';
// Adjust the import according to your file structure

// Create a context
const SessionValidationContext = createContext();

export function useSessionValidation() {
    return useContext(SessionValidationContext);
}

// This component will provide the context
export const SessionValidationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation()

    useEffect(() => {
        // Function to validate session
        const validateSession = () => {
            if (location.pathname !== "/auth/user-roles" && location.pathname !== "/auth/login" && location.pathname !== "/addCampaignApplication" && location.pathname !== "/terms-and-conditions" && window.location.hostname !== "kistpay-portal-v20-qa-ihtuwbv5bq-uc.a.run.app") {
                dispatch(GetToken());
            }

        };

        // Immediately validate the session when the component mounts
        validateSession();

        // Set up a timer to validate session every 30 minutes
        const interval = setInterval(validateSession, 30 * 60 * 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [dispatch, location]);

    return (
        <SessionValidationContext.Provider>
            {children}
        </SessionValidationContext.Provider>
    );
};
