import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
} from "configs/AppConfig";

/**
 * `ProtectedRoute` component is used to protect routes that require authentication.
 * It renders the child components if the user is authenticated, otherwise, it redirects to the login page.
 */
const ProtectedRoute = () => {
  // Accessing the authentication token from the Redux store
  const { token } = useSelector((state) => state.auth);
  // Getting the current location to redirect back after successful login

  // Redirect to the login page if there is no authentication token
  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace
      />
    );
  }
  // If authenticated, render the child components (Outlet represents child routes)
  return <Outlet />;
};

export default ProtectedRoute;
