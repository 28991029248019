import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API, { BASE_URL } from "utils/service";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  campaigns: [],
  financingCampaign: [],
  isCamapignCreated: false,
  catalogueList: [],
  singleCatalogue:{}
};

export const getAllcampaign = createAsyncThunk(
  "order-management/allCampaign",
  async (params, { rejectWithValue }) => {
    try {

      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search
      ? `&query=${params.search}`
      : "";
      const response = await API.get(
        `/order-management/campaigns/list?page=${params.page}${query}${updatedByParam}${startDateParam}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : ""}`
      );

      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllCatalogues = createAsyncThunk(
  "order/catalogues",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/order-management/catalogues/list?page=${params.page}${updatedByParam}${startDateParam}${query}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : "&sort=id,desc"}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteCatalogue = createAsyncThunk(
  "order-management/catalogues-delete",
  async ({ data, id }, { rejectWithValue }) => {
    try {

      const response = await API.patch(
        `/order-management/v1/catalogues/${id}`, data
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getSingleCatalogue = createAsyncThunk(
  "order-management/catalogues-by-id",
  async (param, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/order-management/catalogues/${param}`,

      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllFinancingcampaign = createAsyncThunk(
  "order-management/allCampaignFinancing",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/order-management/campaigns`);

      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteFinancingcampaign = createAsyncThunk(
  "order-management/delete-CampaignFinancing",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(`${BASE_URL}/order-management/campaigns/${params}`);

      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getFinancingcampaignByID = createAsyncThunk(
  "order-management/CampaignFinancingById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/order-management/campaigns/${params}`);

      return response?.data?.payload
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllCatalouge = createAsyncThunk(
  "order-management/CatalougeList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/order-management/catalogues`);
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    SetOrderResponse: (state, action) => {
      state.campaigns = action.payload;
    },
    setisCampaignCreated: (state, action) => {
      state.isCamapignCreated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllcampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllcampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
        state.campaigns = action.payload;
      })
      .addCase(getAllcampaign.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(getAllFinancingcampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFinancingcampaign.fulfilled, (state, action) => {
        state.loading = false;

        state.financingCampaign = action.payload;
      })
      .addCase(getAllFinancingcampaign.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(getFinancingcampaignByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinancingcampaignByID.fulfilled, (state, action) => {
        state.loading = false;

        state.financingCampaign = action.payload;
      })
      .addCase(getFinancingcampaignByID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllCatalouge.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCatalouge.fulfilled, (state, action) => {
        state.loading = false;

        state.catalogueList = action.payload;
      })
      .addCase(getAllCatalouge.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleCatalogue.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleCatalogue.fulfilled, (state, action) => {
        state.loading = false;

        state.singleCatalogue = action.payload;
      })
      .addCase(getSingleCatalogue.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
  },
});

export const { SetOrderResponse, setisCampaignCreated } = orderSlice.actions;

export default orderSlice.reducer;
