import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch } from "react-redux";
/**
 * `AppRoute` component is a wrapper for route components in the application.
 * It allows for conditional application of layout properties, such as using a blank layout.
 *
 * @param {Object} props - The component props.
 * @param {React.Component} props.component - The component to render for this route.
 * @param {string} props.routeKey - A unique key for the route.
 * @param {boolean} props.blankLayout - Determines if the route should use a blank layout.
 */
const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  // Effect to update the layout based on the `blankLayout` prop
  const dispatch = useDispatch();

  useEffect(() => {
    // Determine if a blank layout is required
    const isBlank = blankLayout ? true : false;
    // Dispatch an action to set the layout accordingly
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);
  // Render the component passed as a prop with the additional props
  return <Component {...props} />;
};

export default AppRoute;





