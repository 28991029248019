import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import API, { BASE_URL } from 'utils/service';
import axios from 'axios';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
	// const { email, password } = data
	try {
		const response = await API.post(
			`${BASE_URL}/authentication/v1/auth/login`,
			data
		);

		localStorage.setItem("user", JSON.stringify(response?.data?.payload));
		const token = response?.data?.payload.jwt
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem("lang", "en");
		localStorage.removeItem("isB2B");
		localStorage.removeItem("B2B_TOKEN");
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const signInJazz = createAsyncThunk('auth/login-jazz', async (data, { rejectWithValue }) => {
	// const { email, password } = data
	try {
		const response = await API.post(
			`${BASE_URL}/authentication/v1/auth/login-jazz`,
			data
		);
	
		
		localStorage.setItem("user", JSON.stringify(response?.data?.payload));
		const token = response?.data?.payload.jwt
		localStorage.setItem(AUTH_TOKEN, token);
		
		localStorage.setItem("lang", "en");
		localStorage.removeItem("isB2B");
		localStorage.removeItem("B2B_TOKEN");
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})
export const B2bRefrenceCode = createAsyncThunk('auth/b2b/login', async (data, { rejectWithValue }) => {
	// const { email, password } = data
	try {
		const response = await axios.post(
			`${BASE_URL}/authentication/v1/auth/b2b/login`,
			data
		);
		if(response.data.success){
			localStorage.setItem("isB2B", true);
			const token=response?.data?.payload
			localStorage.setItem("B2B_TOKEN", token);
			// localStorage.setItem("lang", "en");
			return token;
		}
	
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({ email, password })
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout', async () => {
	const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem("lang")
	return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInJazz.pending, (state) => {
				state.loading = true
			})
			.addCase(signInJazz.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInJazz.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(B2bRefrenceCode.pending, (state) => {
				state.loading = true
			})
			.addCase(B2bRefrenceCode.fulfilled, (state, action) => {
				state.loading = false
				// state.redirect = '/'
				// state.token = action.payload
			})
			.addCase(B2bRefrenceCode.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer