import { combineReducers } from 'redux'; // Import combineReducers from redux
import theme from './slices/themeSlice'; // Import theme slice
import auth from './slices/authSlice'; // Import auth slice
import globalSlice from "./slices/GlobalSlice"; // Import global slice
import OrderSlice from "./slices/OrderManagement/order.Slice"; // Import order management slice
import storeSlice from "./slices/StoreManagement/store.Slice"; // Import store management slice
import productSlice from "./slices/ProductManagement/productSlice"; // Import product management slice
import financeSlice from "./slices/FinanceManagement/financSlice"; // Import finance management slice
import userSlice from "./slices/UserManagement/index"; // Import user management slice

/**
 * rootReducer - Combines all reducers including any asynchronously added reducers.
 * @param {Object} asyncReducers - Additional reducers that may be added asynchronously.
 * @returns {Function} - The combined reducer function.
 */

const rootReducer = (asyncReducers) => (state, action) => {
    // Combine all reducers, including the asynchronously added ones
    const combinedReducer = combineReducers({
      theme, // Theme related state management
      globalSlice, // Global state management
      auth, // Authentication state management
      OrderSlice, // Order management state
      storeSlice, // Store management state
      productSlice, // Product management state
      financeSlice, // Finance management state
      userSlice, // User management state
      ...asyncReducers, // Spread any additional reducers
    });
  
    // Return the combined reducer's state and action
    return combinedReducer(state, action);
  };
  
export default rootReducer // Export the rootReducer as the default export
