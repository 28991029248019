import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import API, { BASE_URL } from "utils/service";

export const initialState = {
  loading: false,
  message: "",
  isProductLoading: false,
  showMessage: false,
  brandResponse: [],
  productResponse: [],
  allBrandsList: [],
  id: {},
  allProducts: [],
  allVariants: [],
  allBrands: [],
  allProductDropDown: [],
  productVarients: [],
  dropDownProductVariant: [],
  singleProduct: {},
  singleBrand: {},
  singleVariant: {},
  singleDeviceLogsDetail: {},
  addressDetail: {},
  applicationByOrderID: {},
  singleDeviceLogs: [],
  retailers: [],
  retailerProducts: [],
  allProductType: [],
  allProductattribute: [],
  fetchsingleProduct: {},
  VariantsById: {},
  allProductattributeByProductWise: [],
  allProductCategory: [],
  singleProductType: {},
  productParameter: [],
  productTypeParameter: {},
  singleParameter: {},
  loadingForUniqueCheck: false,
  allProductsDropDown: [],
};
export const CreateBrand = createAsyncThunk(
  "product-management/add-brand",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/brand`,
        data
      );
      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CheckUniqueKey = createAsyncThunk(
  "product-management/v1/unique-check",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product/unique-check?key=${data.key}&value=${data.value}`
      );
      return response.data.payload ? response.data.payload : false;
    } catch (err) {
      if (err.status === 400) {
        return err.data.payload === undefined ? false : err.data.payload;
      }
    }
  }
);
export const CheckUniqueVariantKey = createAsyncThunk(
  "product-management/v1/unique-check-variant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-variant/unique-check?key=${data.key}&value=${data.value}`
      );
      return response.data.payload ? response.data.payload : false;
    } catch (err) {
      if (err.status === 400) {
        return err.data.payload === undefined ? false : err.data.payload;
      }
    }
  }
);

export const UpdateBrand = createAsyncThunk(
  "product-management/v1/update-brand",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/brand/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateProductV2 = createAsyncThunk(
  "product-management/v1/product/update/v2/id",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/product/update/v2/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateProduct = createAsyncThunk(
  "product-management/add-product",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/product`,
        data
      );

      // Check if the status code is 201 (Created)
      if (response.status === 201) {
        // Handle the specific case for 201 status
        // You can return response.data or a specific part of it, depending on your API response structure
        return response.data;
      }

      // Optionally handle the 200 status if needed
      if (response.status === 200) {
        // Handle the 200 OK status
        return response.data;
      }

      // If the response is not 201 or 200, you can either throw an error or handle it differently
      throw new Error("Unexpected response status");
    } catch (err) {
      // Handle the error case
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getBrands = createAsyncThunk(
  "product-management/get-brands",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/brand/list`,
        data
      );
      let value = response?.data?.payload?.content.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getProductCategory = createAsyncThunk(
  "product-management/category",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/category`,
        data
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getProductCategoryPaginated = createAsyncThunk(
  "product-management/category/list",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";

      let sortParam = "";
      if (
        params.column &&
        params.column !== "" &&
        params.order &&
        params.order !== ""
      ) {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/product-management/v1/category/list?page=${params.page}${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateProductCategory = createAsyncThunk(
  "product-management/add-category",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/category`,
        data
      );
      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteBrands = createAsyncThunk(
  "product-management/delete-brands",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/product-management/v1/brand/${params}`
      );
      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteProductType = createAsyncThunk(
  "product-management/v1/delete-product-type/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/product-management/v1/product-type/${params}`
      );
      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getImageID = createAsyncThunk(
  "miscellaneous/file-upload",
  async ({ fileFormData, uploaderId }, { rejectWithValue }) => {
    try {
      // Add uploaderId to the FormData if necessary
      // fileFormData.append("uploaderId", uploaderId);

      // Make the API call with the modified FormData
      const response = await API.post(
        `${BASE_URL}/miscellaneous/image/uploadFile`,
        fileFormData
      );

      // Return the response data or some part of it as needed
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllProductsBrandWise = createAsyncThunk(
  "product-management/all-Products-brand-wise",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/product/brand-wise-product/${params.id}?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllProductsRetailerWise = createAsyncThunk(
  "/v1/retailer-product/retailer/list?retailerId=3",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/retailer-product/retailer/list?retailerId=3&page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllProducts = createAsyncThunk(
  "product-management/all-Products",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/product/list/v2?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const AllProductsDropDown = createAsyncThunk(
  "product-management/all-Products-dropdown-promo",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/product-management/v1/product/list/v2?size=1500`
      );
      const values = response.data.payload.content.map((item) => {
        return {
          label: item?.productName,
          value: item?.id,
        };
      });
      return values;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleProduct = createAsyncThunk(
  "product-management/getSingleProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleProductChangeAPI = createAsyncThunk(
  "product-management/getSingleProduct-changed",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product/v2/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getSingleBrand = createAsyncThunk(
  "product-management/getSingleBrand",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/brand/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateProduct = createAsyncThunk(
  "product-management/v1/product",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/product/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteProduct = createAsyncThunk(
  "product-management/v1/DeleteProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/product-management/v1/product/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteCompany = createAsyncThunk(
  "finance-management/v1/financing-comopany",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/finance/v1/financing-comopany/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteVariant = createAsyncThunk(
  "product-management/v1/product-variant-delete",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/product-management/v1/product-variant/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllProductsListForDropDown = createAsyncThunk(
  "product-management/all-Products-dropdown",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllProductVarients = createAsyncThunk(
  "product-management/all-Products-variants-parameters",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/parameter`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteParameter = createAsyncThunk(
  "product-management/delete-parameter",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/product-management/v1/parameter/${params}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllVariants = createAsyncThunk(
  "product-management/all-Products-variants-list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      const response = await API.get(
        `/product-management/v1/product-variant/product-wise-variant/${
          params.id
        }?page=${params.page}${updatedByParam}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload?.content;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getVariantsById = createAsyncThunk(
  "product-management/all-Products-variants-by-id",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/product-management/v1/product-variant/product-wise-variant/${params}`
      );

      return response?.data?.payload?.content;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllBrands = createAsyncThunk(
  "product-management/all-Product-brands-list",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/product-management/v1/brand/list?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllProductVarientsList = createAsyncThunk(
  "product-management/all-Products-variants",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-variant`
      );

      let value = response?.data?.payload.map((item) => ({
        label: item?.name || "",
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllRetailerList = createAsyncThunk(
  "product-management/all-retailer",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`${BASE_URL}/user-management/v1/retailer`);
      let value = response?.data?.payload.map((item) => ({
        label: item?.name || "",
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAllRetailerProductListbyID = createAsyncThunk(
  "product-management/retailer-product",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/retailer-product/retailer?retailerId=${params}`
      );
      let value = response?.data?.payload.map((item) => ({
        label: item?.name || "",
        value: item.id,
        ...item,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const AddStoreInventory = createAsyncThunk(
  "product-management/v1/store-inventory/save/bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/store-inventory/save/bulk`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const AddRetailerProduct = createAsyncThunk(
  "product-management/v1/retailer-product",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/retailer-product`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateStoreInventory = createAsyncThunk(
  "product-management/v1/update-store-inventory",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/store-inventory/${id}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateFinancingCompany = createAsyncThunk(
  "finance-management/v1/financing-comopany/{id}",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/finance/v1/financing-comopany/${id}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateProductVarient = createAsyncThunk(
  "product-management/create-variants",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/product-variant`,
        data
      );
      // return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateProductVarient = createAsyncThunk(
  "product-management/update-variants",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/product-variant/${id}`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateProductParameter = createAsyncThunk(
  "product-management/create-parameter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/parameter`,
        data
      );
      // return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UploadVariantImage = createAsyncThunk(
  "product-management/product-variant-image",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/product-variant-image`,
        data
      );
      // return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleProductVariant = createAsyncThunk(
  "product-management/Single-Product-Variant",
  async (id, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-variant/${id}`
      );
      return response.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteVariantBulk = createAsyncThunk(
  "product-management/product-variant-bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/product-management/v1/product-variant/delete/bulk`,
        data
      );

      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postDeviceLockSamsung = createAsyncThunk(
  "product-management/device-lock-samsung",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/device-management/v1/device/lock/${id}`,
        data
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdateProductVariant = createAsyncThunk(
  "product-management/update-Product-Variant",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/product-variant/${id}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const postDeviceUnLockSamsung = createAsyncThunk(
  "product-management/device-unlock-samsung",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/device-management/v1/device/unlock/${id}`,
        data
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getDeviceLogs = createAsyncThunk(
  "device-management/get-devicelogs",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/device-management/v1/device/financed?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getSingleLogs = createAsyncThunk(
  "device-management/get-singleLogs",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `/device-management/v1/device-log/device?deviceId=${params.id}?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload?.content;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getDeviceLogsById = createAsyncThunk(
  "device-management/get-devicelogs-byId",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device-log/device?deviceId=${
          params.id
        }&page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAddressById = createAsyncThunk(
  "miscellaneous/get-address-byId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/miscellaneous/v1/address/${data}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getApplicationByOrderID = createAsyncThunk(
  "miscellaneous/get-application-by-addressID",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/device-management/v1/device/application?orderId=${data}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const postAddCatalogue = createAsyncThunk(
  "product-management/add-catalogue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/order-management/catalogues`,
        data
      );
      //return response?.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const AddProductType = createAsyncThunk(
  "product-management/product-type",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/product-type`,
        data
      );
      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getProductType = createAsyncThunk(
  "product-management/product-type-get",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-type/list?page=${
          params.page
        }&sort=pt.id,desc${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getProductTypeDropDown = createAsyncThunk(
  "product-management/product-type-get-all",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-type`
      );

      return response?.data?.payload.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getAttributeByProductType = createAsyncThunk(
  "product-management/product-type-wise",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-type-parameter/product-type-wise-custom/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getParameterById = createAsyncThunk(
  "product-management/v1/parameter/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/parameter/${params}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateParameter = createAsyncThunk(
  "product-management/v1/update-parameter/{id}",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/product-management/v1/parameter/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const AddAttributes = createAsyncThunk(
  "product-management/parameter-value",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/parameter-value/save/bulk`,
        data
      );
      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const AddProductTypeParameter = createAsyncThunk(
  "product-management/product-type-parameter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/product-management/v1/product-type-parameter/save/bulk`,
        data
      );
      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetProductTypeById = createAsyncThunk(
  "product-management/v1/product-type/{id}",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-type/${data}`
      );
      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetProductTypeParameterById = createAsyncThunk(
  "product-management/v1/product-type-parameter-wise-single/{id}",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/product-type-parameter/product-type-wise/${data}`
      );
      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getProductAttribute = createAsyncThunk(
  "product-management/parameter-value-get",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `${BASE_URL}/product-management/v1/parameter/list?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getUOM = createAsyncThunk(
  "rmiscellaneous/uom",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search ? `&query=${params.search}` : "";
      const response = await API.get(
        `${BASE_URL}/miscellaneous/v1/uom/list?page=${
          params.page
        }${updatedByParam}${query}${startDateParam}${endDateParam}${
          params.column !== "" && params.order !== ""
            ? `&sort=${params.column},${params.order}`
            : ""
        }`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const UpdateUOM = createAsyncThunk(
  "rmiscellaneous/update-uom",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/miscellaneous/v1/uom/${id}`,
        data
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateUom = createAsyncThunk(
  "rmiscellaneous/uom",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`${BASE_URL}/miscellaneous/v1/uom`, data);

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteUom = createAsyncThunk(
  "rmiscellaneous/delete-uom",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `${BASE_URL}/miscellaneous/v1/uom/${data}`
      );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getProductAttributeDropDown = createAsyncThunk(
  "product-management/parameter-value-all",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/parameter-value`
      );

      return response?.data?.payload.map((item) => {
        return {
          label: item?.parameter.name,
          value: item?.id,
          ...item,
        };
      });
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const getProductParameterDropDown = createAsyncThunk(
  "product-management/parameter-dropdown",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/product-management/v1/parameter`
      );

      return response?.data?.payload.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
          ...item,
        };
      });
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const UpdateCatalogue = createAsyncThunk(
  "order-management/catalogues-edit",
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        `${BASE_URL}/order-management/v1/catalogues/${id}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    SetProductResponse: (state, action) => {
      state.productResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateBrand.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateBrand.fulfilled, (state, action) => {
        state.loading = false;
        state.brandResponse = action.payload;
      })
      .addCase(CreateBrand.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(CheckUniqueKey.pending, (state) => {
        state.loadingForUniqueCheck = true;
      })
      .addCase(CheckUniqueKey.fulfilled, (state, action) => {
        state.loadingForUniqueCheck = false;
      })
      .addCase(CheckUniqueKey.rejected, (state, action) => {
        state.loadingForUniqueCheck = false;
      })

      .addCase(CreateProduct.pending, (state) => {
        state.loading = true;
        state.isProductLoading = true;
      })
      .addCase(CreateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.productResponse = action.payload;
        state.isProductLoading = false;
      })
      .addCase(CreateProduct.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
        state.isProductLoading = false;
      })
      .addCase(getBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.allBrandsList = action.payload;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getImageID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getImageID.fulfilled, (state, action) => {
        state.loading = false;
        state.id = action.payload;
      })
      .addCase(getImageID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.allProducts = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProductsRetailerWise.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductsRetailerWise.fulfilled, (state, action) => {
        state.loading = false;
        state.allProducts = action.payload;
      })
      .addCase(getAllProductsRetailerWise.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProductsBrandWise.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductsBrandWise.fulfilled, (state, action) => {
        state.loading = false;
        state.allProducts = action.payload;
      })
      .addCase(getAllProductsBrandWise.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProductsListForDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductsListForDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductDropDown = action.payload;
      })
      .addCase(getAllProductsListForDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProductVarients.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductVarients.fulfilled, (state, action) => {
        state.loading = false;
        state.productVarients = action.payload;
      })
      .addCase(getAllProductVarients.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllProductVarientsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductVarientsList.fulfilled, (state, action) => {
        state.loading = false;
        state.dropDownProductVariant = action.payload;
      })
      .addCase(getAllProductVarientsList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllVariants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllVariants.fulfilled, (state, action) => {
        state.loading = false;
        state.allVariants = action.payload;
      })
      .addCase(getAllVariants.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.allBrands = action.payload;
      })
      .addCase(getAllBrands.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(getSingleProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProduct = action.payload;
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleBrand.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleBrand.fulfilled, (state, action) => {
        state.loading = false;
        state.singleBrand = action.payload;
      })
      .addCase(getSingleBrand.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleProductVariant.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProductVariant.fulfilled, (state, action) => {
        state.loading = false;
        state.singleVariant = action.payload;
      })
      .addCase(getSingleProductVariant.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getDeviceLogsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeviceLogsById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleDeviceLogsDetail = action.payload;
      })
      .addCase(getDeviceLogsById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAddressById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAddressById.fulfilled, (state, action) => {
        state.loading = false;
        state.addressDetail = action.payload;
      })
      .addCase(getAddressById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.singleDeviceLogs = action.payload;
      })
      .addCase(getSingleLogs.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getApplicationByOrderID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicationByOrderID.fulfilled, (state, action) => {
        state.loading = false;
        state.applicationByOrderID = action.payload;
      })
      .addCase(getApplicationByOrderID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllRetailerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRetailerList.fulfilled, (state, action) => {
        state.loading = false;
        state.retailers = action.payload;
      })
      .addCase(getAllRetailerList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllRetailerProductListbyID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRetailerProductListbyID.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerProducts = action.payload;
      })
      .addCase(getAllRetailerProductListbyID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getSingleProductChangeAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleProductChangeAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchsingleProduct = action.payload;
      })
      .addCase(getSingleProductChangeAPI.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getProductTypeDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductTypeDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductType = action.payload;
      })
      .addCase(getProductTypeDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getProductAttributeDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductAttributeDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductattribute = action.payload;
      })
      .addCase(getProductAttributeDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAttributeByProductType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttributeByProductType.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductattributeByProductWise = action.payload;
      })
      .addCase(getAttributeByProductType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getProductCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductCategory = action.payload;
      })
      .addCase(getProductCategory.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getVariantsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVariantsById.fulfilled, (state, action) => {
        state.loading = false;
        state.VariantsById = action.payload;
      })
      .addCase(getVariantsById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetProductTypeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetProductTypeById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleProductType = action.payload;
      })
      .addCase(GetProductTypeById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getProductParameterDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductParameterDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.productParameter = action.payload;
      })
      .addCase(getProductParameterDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetProductTypeParameterById.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetProductTypeParameterById.fulfilled, (state, action) => {
        state.loading = false;
        state.productTypeParameter = action.payload;
      })
      .addCase(GetProductTypeParameterById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getParameterById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getParameterById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleParameter = action.payload;
      })
      .addCase(getParameterById.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(AllProductsDropDown.pending, (state) => {
        state.loading = true;
      })
      .addCase(AllProductsDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.allProductsDropDown = action.payload;
      })
      .addCase(AllProductsDropDown.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const { SetProductResponse } = productSlice.actions;

export default productSlice.reducer;
