import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API, { BASE_URL } from "utils/service";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  allorganizationTypeList: [],
  allorganizations: [],
  organizationList: [],
  refrenceStatuses: [],
  allApps: [],
  allUserType: [],
  allRoles: [],
  allMfa: [],
  allPermission: [],
  allAppsPermission: [],
  allUsers: [],
  allAppsByOrganizationId: {},
  dataCheckResponse: {},
  retailerStatusResponse: [],
  retailerChannelResponse: [],
  bannerDetails:{}
};

export const CreateOrganizationType = createAsyncThunk(
  "user-management/create-organization-type",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/organization-type`,
        data
      );
        return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateNewToken = createAsyncThunk(
  "authentication/api-token",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/authentication/v1/auth/api-token`,
        data
      );
        return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllOrganizationType = createAsyncThunk(
  "user-management/all-organization-type",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/organization-type`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateOrganization = createAsyncThunk(
  "user-management/create-organization",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/organization`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllOrganization = createAsyncThunk(
  "user-management/all-organization",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/organization`
      );
      let value = response?.data?.payload
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllRetailerStatus = createAsyncThunk(
  "user-management/retailer-status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/retailer-status`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllRetailerChannel = createAsyncThunk(
  "user-management/retailer-channel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/retailer-channel`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllOrganizationList = createAsyncThunk(
  "user-management/all-organization-list",
  async (params, { rejectWithValue }) => {
    try {
      let query = params.search
      ? `&query=${params.search}`
      : "";
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      const response = await API.get(
        `/user-management/v1/organization/list?page=${params.page}${updatedByParam}${query}${startDateParam}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : ""}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllTokenList = createAsyncThunk(
  "user-management/api-token/list",
  async (params, { rejectWithValue }) => {
    try {
      let query = params.search
      ? `&query=${params.search}`
      : "";
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      const response = await API.get(
        `/user-management/api-token/list?page=${params.page}${updatedByParam}${query}${startDateParam}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : ""}`
      );
      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateCampaign = createAsyncThunk(
  "order-management/create-campaign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/order-management/campaigns`, data
      );

      return response
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const CreateRetailer = createAsyncThunk(
  "order-management/v1/retailer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/retailer`, data
      );

      return response
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllRefrenceStatus = createAsyncThunk(
  "user-management/AllRefrenceStatus",
  async (_,{ rejectWithValue }) => {
    try {
      const response = await API.get(`/user-management/v1/consumer-reference-status`);
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        key: item.id,
        ...item,
      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const GetAllOrganizationApp = createAsyncThunk(
  "user-management/app",
  async (_,{ rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/app`
      );

      let value = response?.data?.payload
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetBannerDetailsByid = createAsyncThunk(
  "v1/banner",
  async (params,{ rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/miscellaneous/v1/banner/${params}`
      );

      let value = response?.data?.payload
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllOrganizationAppForPermission = createAsyncThunk(
  "user-management/app-permission",
  async ( _,{ rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/app`
      );

      let value = response?.data?.payload
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);



export const CreateUserType = createAsyncThunk(
  "user-management/create-userType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/user-type`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateApps = createAsyncThunk(
  "user-management/create-app",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/app`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetAllUserType = createAsyncThunk(
  "user-management/AllUserType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/user-type`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,

      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "user-management/users",
  async (params, { rejectWithValue }) => {
    try {
      let startDateParam = params.startDate ? `&startDate=${params.startDate}` : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy ? `&filterBy=${params.updatedBy}` : "";
      let query = params.search
      ? `&query=${params.search}`
      : "";

      const response = await API.get(
        `/user-management/v1/user/list?page=${params.page}${query}${updatedByParam}${startDateParam}${endDateParam}${params.column !== "" && params.order !== "" ? `&sort=${params.column},${params.order}` : ""}`
      );
      // const response = await API.get(
      //   `${BASE_URL}/user-management/v1/user/list?page=${params.page}`
      // );

      return response?.data?.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const GetAllRoles = createAsyncThunk(
  "user-management/users",
  async (params, { rejectWithValue }) => {
    try {
      let paramsCheck = params.status ? `&statusId=${params.status}` : "";
      let startDateParam = params.startDate
        ? `&startDate=${params.startDate}`
        : "";
      let endDateParam = params.endDate ? `&endDate=${params.endDate}` : "";
      let updatedByParam = params.updatedBy
        ? `&filterBy=${params.updatedBy}`
        : "";
      let query = params.search
        ? `&query=${params.search}`
        : "";

      let sortParam = "";
      if (params.column && params.column !== "" && params.order && params.order !== "") {
        sortParam = `&sort=${params.column},${params.order}`;
      }

      const response = await API.get(
        `/user-management/v1/role/list?page=${params.page
        }${paramsCheck}${updatedByParam}${startDateParam}${query}${endDateParam}${sortParam}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllRolesList = createAsyncThunk(
  "user-management/GetAllRolesList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/role`
      );

      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
        ...item

      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
// user

export const CreateUser = createAsyncThunk(
  "user-management/create-organization",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/user`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const GetMFAType = createAsyncThunk(
  "user-management/MFA-list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/mfa-type`
      );
      let value = response?.data?.payload?.map((item) => ({
        label: item.name,
        value: item.id,
        ...item

      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const GetAllPermission = createAsyncThunk(
  "user-management/permission-list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/permission`
      );
      let value = response?.data?.payload?.map((item) => ({
        name: item.name,
        value: item.id,

      }));
      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const postRole = createAsyncThunk(
  "user-management/postRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/role`,
        data
      );

      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const postPermissions = createAsyncThunk(
  "user-management/postPermissions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/permission`,
        data
      );

      return response?.data.payload;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const getAllAppsByOrganizationID = createAsyncThunk(
  "user-management/getAllAppsByOrganizationID",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `${BASE_URL}/user-management/v1/organization/${params.id}`
      );

      return response?.data?.payload
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const CreateRolePermission = createAsyncThunk(
  "user-management/role-permission/save/bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `${BASE_URL}/user-management/v1/role-permission/save/bulk`,
        data
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DataCheckAPI = createAsyncThunk(
  "/kyc/data-check",
  async ({ data, flag }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/kyc/data-check`, data)

      return { jsonReport: JSON.parse(response?.data?.jsonReport), pdfReport: response?.data?.pdfReport };


    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const DeleteUserRole = createAsyncThunk(
  "user-management/delete/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/user-management/v1/role/${params}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteUser = createAsyncThunk(
  "user-management/user-delete/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/user-management/v1/user/${params}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const DeleteOrganization = createAsyncThunk(
  "user-management/oraganization-delete/{id}",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.delete(
        `/user-management/v1/organization/${params}`
      );
      let value = response?.data?.payload;

      return value;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);


export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateOrganizationType.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateOrganizationType.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateOrganizationType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllOrganizationType.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllOrganizationType.fulfilled, (state, action) => {
        state.loading = false;
        state.allorganizationTypeList = action.payload;
      })
      .addCase(GetAllOrganizationType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllOrganization.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.allorganizations = action.payload;
      })
      .addCase(GetAllOrganization.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllAppsByOrganizationID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAppsByOrganizationID.fulfilled, (state, action) => {
        state.loading = false;
        state.allAppsByOrganizationId = action.payload;
      })
      .addCase(getAllAppsByOrganizationID.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllOrganizationList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllOrganizationList.fulfilled, (state, action) => {
        state.loading = false;
        state.organizationList = action.payload;
      })
      .addCase(GetAllOrganizationList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllOrganizationApp.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllOrganizationApp.fulfilled, (state, action) => {
        state.loading = false;
        state.allApps = action.payload;
      })
      .addCase(GetAllOrganizationApp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllRefrenceStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllRefrenceStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.refrenceStatuses = action.payload;
      })
      .addCase(GetAllRefrenceStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllUserType.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllUserType.fulfilled, (state, action) => {
        state.loading = false;
        state.allUserType = action.payload;
      })
      .addCase(GetAllUserType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllRolesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllRolesList.fulfilled, (state, action) => {
        state.loading = false;
        state.allRoles = action.payload;
      })
      .addCase(GetAllRolesList.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetMFAType.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetMFAType.fulfilled, (state, action) => {
        state.loading = false;
        state.allMfa = action.payload;
      })
      .addCase(GetMFAType.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })

      .addCase(GetAllPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.allPermission = action.payload;
      })
      .addCase(GetAllPermission.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllOrganizationAppForPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllOrganizationAppForPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.allAppsPermission = action.payload;
      })
      .addCase(GetAllOrganizationAppForPermission.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(DataCheckAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(DataCheckAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.dataCheckResponse = action.payload;
      })
      .addCase(DataCheckAPI.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllRetailerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllRetailerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerStatusResponse = action.payload;
      })
      .addCase(GetAllRetailerStatus.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetAllRetailerChannel.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllRetailerChannel.fulfilled, (state, action) => {
        state.loading = false;
        state.retailerChannelResponse = action.payload;
      })
      .addCase(GetAllRetailerChannel.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(GetBannerDetailsByid.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetBannerDetailsByid.fulfilled, (state, action) => {
        state.loading = false;
        state.bannerDetails = action.payload;
      })
      .addCase(GetBannerDetailsByid.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      ;
  },
});



export default userSlice.reducer;
