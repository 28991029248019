import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { GetUserOrganization } from "utils/DefaultFunctions";

export const APP_NAME = GetUserOrganization() === "Outback Supply International" ? "Outback Supply International" : "Kistpay Pvt Ltd.";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const APP_PREFIX_PATH_SETTINGS = "/settings";
export const AUTH_PREFIX_PATH = "/auth";
export const EXTERNAL_PREFIX_PATH = "/";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/ecommerce/website-applications`;
export const UNAUTHENTICATED_ENTRY = window.location.hostname.includes("jazz")?"/login?role=Login":"/user-roles";

let lang = localStorage?.getItem("lang") || "en";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: lang,
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};

export const GLOBAL_CONFIG = {
  tabsIndex: 1,
  isModalOpen: false,
  error: false
};

export const ENUMS = {
  SourceOfIncome: [
    // { label: "NONE", value: 0 },
    { label: "SALARIED", value: 1 },
    { label: "BUSINESS", value: 2 },
    { label: "SELF_EMPLOYED", value: 3 },
  ],
  MaritalStatus: [
    // { label: "NONE", value: 0 },
    { label: "SINGLE", value: 1 },
    { label: "MARRIED", value: 2 },
    { label: "SEPARATED", value: 3 },
    { label: "WIDOWED", value: 4 },
    { label: "DIVORCED", value: 5 },
  ],
  HouseStatus: [
    // { label: "NONE", value: 0 },
    { label: "OWNED", value: 1 },
    { label: "RENTED", value: 2 },
  ],
};

export function joinData(master, summary) {
  // Convert inputs to arrays if they are not
  const masterArray = Array.isArray(master) ? master : [master];
  const summaryArray = Array.isArray(summary) ? summary : [summary];

  return masterArray?.map((masterItem) => {
    const summaryItem = summaryArray?.find(
      (item) => item?.LOAN_NO === masterItem?.LOAN_NO
    );
    return { ...masterItem, ...summaryItem };
  });
}

export function Authorization(arr) {
  const route = window.location.pathname.trim();
  const urlSegment = route.includes("application-details")
    ? route.split("/")[2]
    : route.split("/app/")[1];

  // Filter the array to find all entries where the screen matches the URL segment
  const matchingPermissions = arr.filter((item) => item.screen === urlSegment);

  // If you want to extract just the permissions, you can map the results
  const permissions = matchingPermissions.map((item) => item.permission);

  return permissions;
}

export function generateRandomColor() {
  let color = "#";
  const characters = "0123456789ABCDEF";
  const array = new Uint8Array(6);
  crypto.getRandomValues(array);
  for (let i = 0; i < 6; i++) {
    color += characters[array[i] % 16];
  }
  return color;
}

