import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

const middlewares = [];

const store = configureStore({
	reducer: rootReducer(),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store
}

export default store


// create store inventory
// https://konga.kistpay.com/product-management/v1/store-inventory/
// Request Model

// {
//     "price":"25000",
//     "inventoryCount":2,
//     "retailerProductId":1,
//     "productVariant":{
//         "product":{brand:"",}
//     },
//     "store":{
//         "id":1
//     }
// }

// // create bulk store inventory

// https://konga.kistpay.com/product-management/v1/store-inventory/save/bulk

// Request Model
// {
//     "price":"25000",
//     "inventoryCount":2,
//     "retailerProductId":1,
//     "productVariant":{
//         "id":3
//     },
//     "store":{
//         "id":1
//     }
// }


// list all store Inventory
// https://konga.kistpay.com/product-management/v1/store-inventory/list

// create store
// https://konga.kistpay.com/product-management/v1/store/

// Request Model
// {
//     "storeType":{
//         "id":1
//     },
//     "retailerId":1,
//     "addressId":1,
//     "name":"Saad Qureshi and Co",
//     "publicId":"09007"

// }

// // list all store

// https://konga.kistpay.com/product-management/v1/store/list 

// {
// 	"message": "Data fetched.",
// 	"success": true,
// 	"payload": {
// 	  "content": [
// 		{
// 		  "id": 1,
// 		  "updatedDate": "2023-11-22T19:50:33.029017",
// 		  "createdBy": 0,
// 		  "updatedBy": 0,
// 		  "price": 25000,
// 		  "inventoryCount": 2,
// 		  "retailerProductId": 1,
// 		  "productVariant": {
// 			"id": 3,
// 			"updatedDate": "2023-11-22T19:48:11.590543",
// 			"createdBy": 0,
// 			"updatedBy": 0,
// 			"product": {
// 			  "id": 3,
// 			  "updatedDate": "2023-11-22T19:25:40.674",
// 			  "createdBy": 0,
// 			  "updatedBy": 0,
// 			  "brand": {
// 				"id": 1,
// 				"updatedDate": "2023-11-22T19:25:13.480173",
// 				"createdBy": 0,
// 				"updatedBy": 0,
// 				"name": "Samsung",
// 				"description": "Samsung the one and only smart phone",
// 				"publicId": "12345",
// 				"imageId": 1,
// 				"deletedRecord": false,
// 				"createdAt": "2023-11-22T19:25:13.480173"
// 			  },
// 			  "imageId": 1,
// 			  "name": "Samsung A04",
// 			  "description": "Samsung A04 the best smart phone in the lifespan of smartness",
// 			  "publicId": "09007",
// 			  "deletedRecord": false,
// 			  "createdAt": "2023-11-22T19:25:40.674"
// 			},
// 			"deletedRecord": false,
// 			"createdAt": "2023-11-22T19:48:11.590543"
// 		  },
// 		  "store": {
// 			"id": 1,
// 			"updatedDate": "2023-11-22T19:39:22.077472",
// 			"createdBy": 0,
// 			"updatedBy": 0,
// 			"storeType": {
// 			  "id": 1,
// 			  "updatedDate": "2023-11-22T19:36:43.105079",
// 			  "createdBy": 0,
// 			  "updatedBy": 0,
// 			  "name": "Retailer",
// 			  "deletedRecord": false,
// 			  "createdAt": "2023-11-22T19:36:43.105079"
// 			},
// 			"retailerId": 1,
// 			"addressId": 1,
// 			"name": "Saad Qureshi and Co",
// 			"publicId": "09007",
// 			"deletedRecord": false,
// 			"createdAt": "2023-11-22T19:39:22.077472"
// 		  },
// 		  "deletedRecord": false,
// 		  "createdAt": "2023-11-22T19:50:33.029017"
// 		}
// 	  ],
// 	  "pageable": {
// 		"pageNumber": 0,
// 		"pageSize": 25,
// 		"sort": {
// 		  "sorted": true,
// 		  "empty": false,
// 		  "unsorted": false
// 		},
// 		"offset": 0,
// 		"paged": true,
// 		"unpaged": false
// 	  },
// 	  "last": true,
// 	  "totalPages": 1,
// 	  "totalElements": 1,
// 	  "size": 25,
// 	  "number": 0,
// 	  "sort": {
// 		"sorted": true,
// 		"empty": false,
// 		"unsorted": false
// 	  },
// 	  "first": true,
// 	  "numberOfElements": 1,
// 	  "empty": false
// 	}
//   }